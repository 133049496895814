export default {
  methods: {
    valueFormatToBack(field, fieldValue) {
      if (field) {
        if (field.type === "file") {
          return fieldValue && fieldValue.length ? fieldValue[0] : "";
        }
        if (field.type === "date") {
          return new Date(fieldValue).toJSON().split("T")[0];
        }
        if (field.type === "datetime") {
          return new Date(fieldValue).toJSON().replace("Z", "");
        }
        if (field.type === "time") {
          return new Date(fieldValue)
            .toJSON()
            .split("T")[1]
            .replace("Z", "");
        }
        if (field.items && field.items.length) {
          for (const item of field.items) {
            if (fieldValue === item.value) {
              return item.id;
            }
          }
        }
      }
      return fieldValue;
    }
  }
};
