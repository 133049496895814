<template>
  <div class="note-user-details" v-if="eventRequest && !this.loading">
    <div class="row gy-4 mb-4">
      <div class="col col-12 col-md-3 d-flex justify-content-end">
        <Avatar
          :src="eventRequest.user.avatar"
          size="100%"
          class="w-100"
        ></Avatar>
      </div>
      <div class="col col-12 col-md-6">
        <div class="note-last-name">
          {{ eventRequest.user.last_name_display }}
        </div>
        <div class="note-first-middle-name">
          {{ officialName }}
        </div>
        <div class="note-data">
          <div
            v-for="(data, key) in baseData"
            :key="`user_fields_${key}`"
            :class="{ 'd-none': !data.value && data.hideIfEmpty }"
          >
            <div>{{ data.title }}</div>
            <ProfileNoteDetailsInput
              class="d-flex justify-content-between align-items-baseline"
              v-bind="data"
              @input="e => getBaseDateInputFunc(data, e)"
            >
            </ProfileNoteDetailsInput>
          </div>
          <div
            v-for="(data, key) in shownFields"
            :key="`request_fields_${key}`"
            :class="{
              'ignore-line': data.type === 'textarea'
            }"
          >
            <TitleBold center v-if="data.type === 'textarea'">
              {{ data.label }}
            </TitleBold>
            <div v-else>{{ data.label }}</div>
            <ProfileNoteDetailsInput
              v-bind="data"
              :value="fieldValues[data.id]"
              @input="e => changeDate(data.id, e)"
            >
            </ProfileNoteDetailsInput>
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-3 note-actions">
        <div class="note-id mb-3 d-flex justify-content-center">
          <div>
            <div class="description">
              {{ $t("event.request.texts.id").toLowerCase() }}
            </div>
            <div class="count">
              {{ $t("number") }}{{ this.eventRequest.id }}
            </div>
          </div>
        </div>
        <div class="d-grid gap-1">
          <FilledButton
            block
            class="mb-1"
            color="dark"
            :to="{ name: 'EventDetails', params: { slug: event.slug } }"
          >
            {{ eventNames.find(val => val.value === event.event_type).label }}
          </FilledButton>
          <FilledButton
            block
            class="mb-1"
            color="primary"
            :to="{ name: 'NoteOperations', params: { id: eventRequest.id } }"
          >
            {{ $t("event.request.operations.name") }}
          </FilledButton>
          <template v-if="$store.getters.userIsAdmin">
            <FilledButton
              block
              class="mb-1"
              color="danger"
              v-if="!deleteConfirm"
              @click="() => (deleteConfirm = true)"
              >{{ $t("event.actions.delete") }}
            </FilledButton>
            <div class="btn-group" v-else>
              <FilledButton
                block
                class="mb-1"
                color="danger"
                @click="deleteEventRequest"
                >{{ $t("event.actions.delete") }}
              </FilledButton>
              <FilledButton
                block
                class="mb-1"
                color="secondary"
                @click="() => (deleteConfirm = false)"
                >{{ $t("event.actions.cancel") }}
              </FilledButton>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import TitleBold from "../components/base/TitleBold";
import FilledButton from "../components/buttons/FilledButton";
import Avatar from "../components/base/Avatar";
import showdown from "showdown";
import API from "../common/api/api.functions";
import loadingMixin from "../mixins/loadingMixin";
import { mapActions, mapGetters } from "vuex";
import ProfileNoteDetailsInput from "../components/inputs/ProfileNoteDetailsInput";
import valueToFieldMixin from "../mixins/valueToFieldMixin";
import wsMixin from "../mixins/wsMixin";
import localizeMixin from "../mixins/localizeMixin";

export default {
  name: "NoteDetails",
  components: {
    ProfileNoteDetailsInput,
    Avatar,
    FilledButton,
    TitleBold
  },
  mixins: [loadingMixin, valueToFieldMixin, wsMixin, localizeMixin],
  data: () => ({
    mdToHtml: null,
    event: null,
    eventRequest: null,
    fields: [],
    fieldValues: {},
    baseData: [],
    deleteConfirm: false,
    canEdit: false
  }),
  computed: {
    ...mapGetters([
      "eventNames",
      "eventArticleTypes",
      "eventArticleType",
      "requestStatus"
    ]),
    noteId() {
      return this.$route.params.id || null;
    },
    officialName() {
      if (!this.eventRequest) return "";
      let names = [];
      if (this.eventRequest.user.first_name_display)
        names.push(this.eventRequest.user.first_name_display);
      if (this.eventRequest.user.middle_name_display)
        names.push(this.eventRequest.user.middle_name_display);
      return names.join(" ");
    },
    shownFields() {
      return this.fields.filter(
        val =>
          !val.userField && !val.hidden && !val.deleted && this.isShown(val)
      );
    }
  },
  methods: {
    ...mapActions(["fieldToFront", "fieldToBack"]),
    async deleteEventRequest() {
      if (this.$store.getters.userIsAdmin) {
        await API.eventRequestDelete(this.eventRequest.id)
          .then(async () => {
            this.$toast.success("Заявка успешно удалена");
            await this.$router.push({
              name: "EventDetails",
              params: { slug: this.event.slug }
            });
          })
          .catch(e =>
            this.$toast.error(
              e.response?.data
                ? JSON.stringify(e.response.data)
                : "Неизвестная ошибка"
            )
          );
      }
      this.deleteConfirm = false;
    },
    async updateFields() {
      let modifiedFields = [];
      for (const field of this.event.fields) {
        const formattedField = await this.fieldToFront({ field });
        // modifiedFields = [...modifiedFields, formattedField];
        let content = this.eventRequest.contents.find(
          val => val.cf === field.id
        );
        if (content) {
          this.fieldValues[field.id] = content.value;
        } else {
          this.fieldValues[field.id] = null;
        }
        formattedField.editable = this.canEdit;
        if (formattedField.userField && this.eventRequest?.user) {
          let newVal =
            this.eventRequest?.user[formattedField.userField] || null;
          if (formattedField.type === "radio_buttons" && newVal) {
            newVal = [newVal];
          }
          this.fieldValues[formattedField.id] = newVal;
          formattedField.disabled = !!newVal;
          formattedField.editable = !newVal;
        }
        modifiedFields = [...modifiedFields, formattedField];
      }

      this.fields = modifiedFields;
    },
    async getBaseDateInputFunc(data, e) {
      if (data.inputFunc) {
        await data.inputFunc(e);
      }
    },
    updateBaseDate() {
      this.baseData = [
        {
          title: this.$t("event.texts.section"),
          value: this.eventRequest.section,
          type: "select",
          oneValue: true,
          items: this.event.sections.map(val => ({
            value: val.id,
            label: val.title
          })),
          hideIfEmpty: true,
          editable: this.canEdit,
          inputFunc: this.changeSection
        },
        {
          title: this.$t("event.request.operations.created"),
          value: this.eventRequest.created,
          type: "datetime",
          hideIfEmpty: true
        },
        {
          title: this.$t("event.request.operations.updated"),
          value: this.eventRequest.updated,
          type: "datetime",
          hideIfEmpty: true
        },
        {
          title: this.$t("event.details.participationType"),
          value: this.eventArticleType(this.eventRequest.participation_type),
          type: "text",
          hideIfEmpty: true
        },
        {
          title: this.$t("event.request.texts.status"),
          value: this.requestStatus(this.eventRequest.status),
          type: "text",
          hideIfEmpty: true
        },
        {
          title: this.$t("user.fields.sex"),
          value: this.eventRequest.user.sex,
          inline: true,
          button: true,
          buttonGroup: true,
          type: "radio_buttons",
          items: [
            {
              label: this.$t("user.fields.sexItems.male"),
              value: "male"
            },
            {
              label: this.$t("user.fields.sexItems.female"),
              value: "female"
            }
          ]
        },
        {
          title: this.$t("user.fields.phone"),
          value: this.eventRequest.user.phone,
          type: "tel"
        },
        {
          title: this.$t("user.fields.email"),
          value: this.eventRequest.user.email,
          type: "email"
        },
        {
          title: this.$t("user.fields.birthday"),
          value: this.eventRequest.user.birthday,
          type: "date"
        }
      ];
    },
    changeEditStatus(index) {
      this.baseData[index].nowEdit = !this.baseData[index].nowEdit;
      this.baseData = [...this.baseData];
    },
    async changeDate(index, value) {
      let outValue = this.valueFormatToBack(
        this.fields.find(val => val.id === index),
        value
      );
      const formData = new FormData();
      if (Array.isArray(outValue)) {
        for (const innerValue of outValue) {
          formData.append(`field_${index}`, innerValue);
        }
      } else formData.append(`field_${index}`, outValue);
      const newUserData = await API.eventRequestEdit(
        this.eventRequest.id,
        formData
      )
        .then(res => {
          this.$toast.success("Информация обновлена");
          return res.data;
        })
        .catch(error => {
          this.$toast.error(JSON.stringify(error?.response?.data || {}));
          return null;
        });
      if (newUserData) this.eventRequest = newUserData;
      await this.updateFields();
    },
    async changeSection(value) {
      const formData = new FormData();
      formData.append(`section`, value);
      const newUserData = await API.eventRequestEdit(
        this.eventRequest.id,
        formData
      )
        .then(res => {
          this.$toast.success("Информация обновлена");
          return res.data;
        })
        .catch(error => {
          this.$toast.error(JSON.stringify(error?.response?.data || {}));
          return null;
        });
      if (newUserData) this.eventRequest = newUserData;
      await this.updateBaseDate();
    },
    isShown(element) {
      if (!element.eventTypeRequired) return true;
      if (element.participationTypes?.length) {
        let participationTypesSet = new Set([...element.participationTypes]);
        let intersection = [
          ...([this.eventRequest.participation_type] || [])
        ].filter(x => participationTypesSet.has(x));
        return !!intersection.length;
      }
      if (element.eventTypes?.length) {
        let eventTypesSet = new Set([...element.eventTypes]);
        let intersection = [...([this.event.event_type] || [])].filter(x =>
          eventTypesSet.has(x)
        );
        return !!intersection.length;
      }
      return true;
    }
  },
  async mounted() {
    this.$store.commit(
      "UPDATE_TAB_TITLE",
      `${this.$t("event.request.details.title")} ${this.$t("number")}${
        this.noteId
      }`
    );
    this.mdToHtml = new showdown.Converter();
    this.loading++;
    this.eventRequest = await API.getEventRequestDetails(this.noteId)
      .then(res => res.data)
      .catch(error => {
        this.$toast.error(JSON.stringify(error?.response?.data || {}));
        this.notFound = true;
      });
    if (!this.eventRequest || this.notFound) {
      this.notFound = true;
      this.loading--;
      return;
    }
    this.event = await API.getEventDetails(this.eventRequest.event)
      .then(res => res.data)
      .catch(error => {
        this.$toast.error(JSON.stringify(error?.response?.data || {}));
        this.notFound = true;
      });
    if (this.notFound || !this.event) {
      this.notFound = true;
      this.loading--;
      return;
    }
    // this.eventTitle = eventResponse.title;
    this.$store.commit("UPDATE_HEADER_TITLE", this.event.title || null);

    await this.updateFields();

    this.$store.commit("UPDATE_HEADER_IMAGE", this.event.big_image);
    this.updateBaseDate();
    this.loading--;
    this.ws.send(
      JSON.stringify({
        message_type: "granted_operation_types",
        request: this.eventRequest.id
      })
    );
  },
  watch: {
    async wsLastData(val) {
      let content = val.content;
      let message_type = val.message_type;
      if (
        message_type === "granted_operation_types" &&
        content.request === this.eventRequest.id
      ) {
        this.requestOperationTypesIds = content.granted_operations || [];
        if (this.requestOperationTypesIds.length)
          this.selectedType = this.requestOperationTypesIds.includes(0)
            ? 0
            : this.requestOperationTypesIds[0];
        else this.selectedType = 0;
        this.eventRequest.status = content.status;
        this.canEdit = !!content.can_edit;
        await this.updateFields();
        this.updateBaseDate();
      } else if (
        message_type === "new_message" &&
        content.request === this.eventRequest.id
      ) {
        this.ws.send(
          JSON.stringify({
            message_type: "granted_operation_types",
            request: this.eventRequest.id
          })
        );
      }
    }
  }
};
</script>
