import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["language"]),
    otherLang() {
      return this.language === "ru" ? "en" : "ru";
    }
  },
  methods: {
    choosePrioritized(element, field) {
      return element[`${field}_${this.language}`]
        ? element[`${field}_${this.language}`]
        : element[`${field}_${this.otherLang}`];
    },
    localize(element) {
      let items = element.items;
      let outputObject = {
        ...element,
        label: this.choosePrioritized(element, "label"),
        help: this.choosePrioritized(element, "help")
      };
      if (items) {
        items = items.map(val => ({
          ...val,
          label: this.choosePrioritized(val, "label")
        }));
        outputObject.items = items;
      }
      return outputObject;
    }
  }
};
