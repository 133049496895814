<template>
  <div
    class="d-flex"
    :class="{
      'flex-column align-content-end':
        nowEdit && computedAttrs.type === 'textarea'
    }"
  >
    <validation-observer ref="observer">
      <RegInput
        v-if="nowEdit"
        v-model="nowValue"
        v-bind="computedAttrs"
        class="flex-grow-1"
        :validate-disabled="false"
        :class="{
          'w-100': computedAttrs.type === 'textarea'
        }"
      ></RegInput>
      <div v-else class="flex-grow-1">
        <template v-if="computedAttrs.type === 'textarea'">
          <p v-for="stringNode in viewValue" :key="stringNode">
            {{ stringNode }}
          </p>
        </template>
        <template v-else-if="computedAttrs.type === 'file'">
          <div class="btn-group" v-if="this.value">
            <FilledButton target="_blank" :href="viewValue" color="primary">
              {{ $t("event.request.texts.downloadFile") }}
            </FilledButton>
            <FilledButton
              v-if="!computedAttrs.required && value && editable"
              color="danger"
              @click.prevent="emitEmpty"
            >
              <SvgIcon icon="trash" color="current" height="16"></SvgIcon>
            </FilledButton>
          </div>
          <template v-else>
            {{ $t("noValue") }}
          </template>
        </template>
        <template v-else>
          {{ viewValue }}
        </template>
      </div>
    </validation-observer>
    <div
      class="flex-shrink-0 d-flex justify-content-end ms-2 change-state-edit"
    >
      <a href="#" @click.prevent="startEdit" v-if="editable && !nowEdit">
        <SvgIcon
          hover="warning"
          icon="pencil"
          color="current"
          height="16"
        ></SvgIcon>
      </a>
      <a
        href="#"
        @click.prevent="redoEdit"
        v-if="editable && nowEdit"
        class="me-2"
      >
        <SvgIcon
          hover="danger"
          icon="redo"
          color="current"
          height="16"
        ></SvgIcon>
      </a>
      <a href="#" @click.prevent="approveEdit" v-if="editable && nowEdit">
        <SvgIcon
          hover="success"
          icon="check"
          color="current"
          height="16"
        ></SvgIcon>
      </a>
    </div>
  </div>
</template>

<script>
import RegInput from "./RegInput";
import SvgIcon from "../base/SvgIcon";
import FilledButton from "../buttons/FilledButton";
import { ValidationObserver } from "vee-validate";
import parsePhoneNumber from "libphonenumber-js";
import localizeMixin from "../../mixins/localizeMixin";

export default {
  name: "ProfileNoteDetailsInput",
  components: { RegInput, SvgIcon, ValidationObserver, FilledButton },
  props: {
    editable: {
      type: Boolean,
      default: () => false
    },
    value: {
      default: () => null
    }
  },
  data: () => ({
    nowEdit: false,
    nowValue: null
  }),
  mixins: [localizeMixin],
  computed: {
    computedAttrs() {
      const outAttrs = { ...this.$attrs };
      if (outAttrs.label) delete outAttrs.label;
      if (outAttrs.value) delete outAttrs.value;
      if (outAttrs.class) delete outAttrs.class;
      if (outAttrs.updateKey) delete outAttrs.updateKey;
      return outAttrs;
    },
    viewValue() {
      if (this.$attrs.type === "tel")
        return this.value
          ? parsePhoneNumber(this.value).formatInternational()
          : this.noValue;
      if (this.$attrs.type === "textarea")
        return (this.value || this.noValue).split(/[\n\r]+/);
      if (this.$attrs.type === "checkbox") return this.value ? "да" : "нет";
      if (this.$attrs.type === "date")
        return this.value
          ? new Date(this.value).toLocaleDateString(this.language)
          : this.noValue;
      if (this.$attrs.type === "datetime")
        return this.value
          ? new Date(this.value).toLocaleString(this.language)
          : this.noValue;
      if (this.$attrs.type === "time")
        return this.value
          ? new Date(this.value).toLocaleTimeString(this.language)
          : this.noValue;
      if (this.$attrs.items && Array.isArray(this.$attrs.items)) {
        const searchVals = Array.isArray(this.value)
          ? this.value
          : [this.value];
        const outVals = [];
        searchVals.forEach(searchVal => {
          const nowValItem = this.$attrs.items.find(
            val => val.value === searchVal
          );
          if (nowValItem?.label) outVals.push(nowValItem.label);
        });
        if (outVals.length) return outVals.join(", ");
        return this.noValue;
      }
      if (this.value) return this.value;
      return this.noValue;
    }
  },
  methods: {
    valueUpdate(val) {
      if (this.$attrs.items && Array.isArray(this.$attrs.items)) {
        this.nowValue = Array.isArray(val) ? val : [val];
      } else {
        this.nowValue = val;
      }
      this.nowEdit = false;
    },
    startEdit() {
      this.nowEdit = true;
    },
    redoEdit() {
      this.valueUpdate(this.value);
    },
    async emitEmpty() {
      this.$emit("input", null);
    },
    async approveEdit() {
      if (this.nowValue === this.value) {
        this.nowEdit = false;
        return;
      }
      for (const nowRef of Object.values(this.$refs.observer.refs)) {
        if (nowRef.disabled) continue;
        nowRef.setFlags({ dirty: true });
      }
      let validation = await this.$refs.observer.validate();
      if (!validation) {
        this.$toast.error("Исправьте ошибки!");
        let refs = this.$refs.observer.refs;
        for (const ref of Object.values(refs)) {
          if (ref.errors.length) {
            ref.$el.scrollIntoView({ block: "center", behavior: "smooth" });
            break;
          }
        }
        return;
      }
      this.$emit("input", this.nowValue);
    }
  },
  watch: {
    value(val) {
      this.valueUpdate(val);
    }
  },
  mounted() {
    this.valueUpdate(this.value);
  }
};
</script>
